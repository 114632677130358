@use 'styles/colors';
@use 'styles/utils';

.container {
  position: absolute;
  top: 0px;
  bottom: 0;
  transition: right 250ms ease;
  overflow: hidden;
  background-color: colors.$white;
  box-shadow: 0 0 0.4rem #000000;
  z-index: 101;
}

.overlay {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.fixed {
  position: fixed;
  top: utils.$HEADER_HEIGHT;
}
